import { includes } from "lodash";
import { Roles } from "../constants/Enums";

export const isClient = (user) => user?.user_type === Roles.CLIENT;
export const isAdmin = (user) =>
   user?.user_type === Roles.ADMIN || user?.user_type === Roles.SUPERADMIN;
export const isManager = (user) => user?.user_type === Roles.MANAGER;

export const canUpdateAsset = (asset, user) => {
   if (!user) return false;

   if (!isClient(user)) return true;

   return isClient(user) && user.id === asset.owner?.id;
};


export const canManageTaskManager = ({ user_type: userType } = {}) => {
  if (!userType) {
     return false;
  }
  return includes([Roles.SUPERADMIN, Roles.ADMIN, Roles.MANAGER], userType);
};
