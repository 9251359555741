import { InMemoryCache } from "@apollo/client";
import { layoutSettingsVar, globalSettingsVar } from "../apollo/local/cache";

const apolloCache = new InMemoryCache({
   addTypename: true,
   typePolicies: {
      Project: {
         //for reference
         keyFields: ["id"],
      },
      Query: {
         fields: {
            layoutSettings: {
               read() {
                  return layoutSettingsVar();
               },
            },
            globalSettings: {
               read() {
                  return globalSettingsVar();
               },
            },
            users: {
               merge(existing = [], incoming = []) {
                  return incoming;
               },
            },
            assetCategories: {
               merge(existing = [], incoming = []) {
                  return incoming;
               },
            },
            assets: {
               merge(existing = [], incoming = []) {
                  return incoming;
               },
            },
            privateTaxProvider: {
               merge(existing = [], incoming = []) {
                  return incoming;
               },
            },
            privateTaxProviderCosts: {
               merge(existing = [], incoming = []) {
                  return incoming;
               },
            },
            events: {
               merge(existing = [], incoming = []) {
                  return incoming;
               },
            },
            charityDonations: {
               merge(existing = [], incoming = []) {
                  return incoming;
               },
            },
            estimateRequests: {
               merge(existing = [], incoming = []) {
                  return incoming;
               },
            },
            estimateRequestRecipients: {
               merge(existing = [], incoming = []) {
                  return incoming;
               },
            },
            crmContacts: {
               merge(existing = [], incoming = []) {
                  return incoming;
               },
            },
            crmNegotiations: {
               merge(existing = [], incoming = []) {
                  return incoming;
               },
            },
            crmEvents: {
               merge(existing = [], incoming = []) {
                  return incoming;
               },
            },
            crmTemplates: {
               merge(existing = [], incoming = []) {
                  return incoming;
               },
            },
            dossierCategory: {
               merge(existing = [], incoming = []) {
                  return incoming;
               },
            },
            task: {
               merge(existing = [], incoming = []) {
                  return incoming;
               },
            },
            taskUser: {
               merge(existing = [], incoming = []) {
                  return incoming;
               },
            },
            taskNote: {
               merge(existing = [], incoming = []) {
                  return incoming;
               },
            },
            taskMessage: {
               merge(existing = [], incoming = []) {
                  return incoming;
               },
            },
         },
      },
   },
});

export default apolloCache;
