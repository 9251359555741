import moment from "moment";
import { DATETIME_FORMAT, DATE_FORMAT } from "../constants/App";

export const formatDate = (date, outFormat = DATE_FORMAT, inFormat = null) => {
   if (!date) return null;
   let m = null;
   if (inFormat) {
      m = moment(date, inFormat);
   } else {
      m = moment(date);
   }
   return m.format(outFormat);
};

export const formatDateTime = (date) => {
   return formatDate(date, DATETIME_FORMAT);
};

export function convertSeconds(seconds) {
   const days = Math.floor(seconds / (24 * 60 * 60)); // calculate days
   seconds %= 24 * 60 * 60; // remainder after removing days

   const hours = Math.floor(seconds / (60 * 60)); // calculate hours
   seconds %= 60 * 60; // remainder after removing hours

   const minutes = Math.floor(seconds / 60); // calculate minutes
   seconds %= 60; // remainder is seconds

   return {
      days,
      hours,
      minutes,
      seconds,
   };
}
